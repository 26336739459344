<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$route.path': function(){
      window.scrollTo(0,0)
    }
  }
}
</script>

<style>
html, body{
  margin:0;
  padding:0;
}
*{
  font-family: "PingFang SC", "Microsoft YaHei", "微软雅黑", Arial, sans-serif
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
