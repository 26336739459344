import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/m',
            component: () => import('@/components/mobile/index.vue'),
        },
        {
            path: '/mdownload',
            component: () => import('@/components/mobile/download.vue'),
        },
        // 门户
        {
            path: '/',
            component: () => import('@/components/homepage.vue'),
            children: [
                {
                    path: '/',
                    component: () => import('@/components/Home.vue'),
                },
                {
                    path: '/about',
                    component: () => import('@/components/about.vue'),
                },
                {
                    path: '/cooperation',
                    component: () => import('@/components/cooperation.vue'),
                },
                {
                    path: '/news',
                    component: () => import('@/components/news.vue'),
                },
                {
                    path: '/askJoin',
                    component: () => import('@/components/askJoin.vue'),
                },
                {
                    path: '/download',
                    component: () => import('@/components/download.vue'),
                },
            ]
        },
        {
            path: '/profileUser',
            component: () => import('@/components/PrivacyPolicy1.vue'),
        },
        {
            path: '/profilePrivacy',
            component: () => import('@/components/PrivacyPolicy2.vue'),
        },
        {
            path: '/profileChildren',
            component: () => import('@/components/PrivacyPolicy3.vue'),
        },

        {
            path: '*',
            redirect: '/'
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.length <= 0) {
        next('/')
        // from.path ? next(from.path) : next('/home')
    } else {
        next()
    }
})

export default router
